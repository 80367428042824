import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import Seo from '../components/seo'
import Layout from '../layouts/layout'
import AwardListInHeader from '../components/blocks/AwardListInHeader'
import DontJustTake from '../components/UI/DontJustTake'
import EasilyCalculate from '../components/ForPersonalLoans/PersonalLoanPages/EasilyCalculate'
import HeaderWithImage from '../components/ForProgPage/HeaderWithImage'
import PageContent from '../components/ForProgPage/PageContent'
import BgCircleGreenBlue from '../assets/images/svg/bg-circle-green-blue.svg'
import { aggregateRating } from '../support/structuredDataConfig/dontJustTakeReview'
import AreatenSnippet from '../components/blocks/AreatenSnippet';

const ProgPagesTemplate = ({ data, path }) => {

  const page = data.programmaticContentJson

  const structuredData = {
    "@context": "https://schema.org",
    "review": {
      "@type": "Review",
      "itemReviewed": {
        "@type": "Product",
        "name": "SocietyOne",
        "review": {
          "@type": "Review",
          "reviewRating": {
              "@type": "Rating",
              "ratingValue": "4.7"
          },
          "author": {
            "@type": "Organization",
            "name": "Google"
          }
        },
        "aggregateRating": aggregateRating,
      },
      "reviewRating": {
        "@type": "AggregateRating",
        "ratingValue": "4.7",
        "ratingCount": "30"
      },
      "author": {
        "@type": "Organization",
        "name": "Google"
      }
    }
  }
  return (
    <AreatenSnippet slug={page.slug}>
      <Layout hasGetYourRate={true}>
        <Seo 
          title={page.title} 
          description={page.desc} 
          path={path}
          structuredData={structuredData}
        />
        <HeroWrapper>
          <HeaderWithImage page={page}/>
          <ImageWrapper>
            <BgCircleGreenBlue className="big" />
          </ImageWrapper>
        </HeroWrapper>
        <AwardListInHeader>
          <p>Award winning personal loans</p>
        </AwardListInHeader>
        <EasilyCalculate header="Easily calculate your loan repayments" content="Getting a personal loan with SocietyOne is quick and easy."/>
        <PageContent />
        <DontJustTake background={"var(--base-gray)"} />
      </Layout>
    </AreatenSnippet>
  )
}

const HeroWrapper = styled.div`
  position: relative;
  overflow: hidden;
`
const ImageWrapper = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	max-width: 1440px;
	margin-right: auto;
	margin-left: auto;

	.big {
    position: absolute;
    left: auto;
    top: -15vw;
    right: -15.9vw;
    bottom: auto;
    width: 49vw;
    height: 49vw;
    max-height: 700px;
    max-width: 700px;
	}

  @media screen and (max-width: 767px) {
    .big {
      left: auto;
      top: auto;
      right: 1vw;
      bottom: 7em;
      width: 50vw;
      height: 50vw;
    }
  }
  @media screen and (max-width: 479px) {
    .big {
      bottom: 9%;
      width: 60vw;
      height: 60vw;
    }
  }
`

export default ProgPagesTemplate

export const pageQuery = graphql`
  query PageProgPageBySlug(
    $slug: String!
  ) {
    programmaticContentJson(slug: {eq: $slug}) {
      keyword
      slug
      h1
      title
      desc
    }
  }
`

