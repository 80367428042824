import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

// the contents need to be fetch from cms or .json file in data folder if not need to dynamically shown
const Card = ({content, title, date, icon, alt}) => {
  return (
    <CardWrapper>
      <Content>
        <p>{content}</p>
      </Content>
      <Info>
        <Icon>
          <GatsbyImage image={icon} alt={alt} />
        </Icon>
        <Text>
          <Title>{title}</Title>
          <Date>{date}</Date>
        </Text>
      </Info>
    </CardWrapper>
  )
}

const CardWrapper = styled.div`
  border: 1px solid rgb(200 200 200);
  border-radius: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 24px;
  margin: 50px 10px;
  @media screen and (max-width: 768px) {
    width: 70%;
  }
  @media screen and (max-width: 479px) {
    width: 100%;
    margin: 50px 0;
  }
`
const Content = styled.div`
  max-width: 480px;
  p {
    font-size: 23px;
    margin: 0 0 24px 0;
  }
  @media screen and (max-width: 768px) {
    max-width: 100%;
    p {
      font-size: 16px;
    }  
  }
`
const Info = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const Icon = styled.div`
  margin-right: 12px;
  width: 56px;
  height: auto;
  overflow: hidden;
  img {
    width: 100%;
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    margin-right: 10px;
    width: 40px;
  }
`
const Text = styled.div`
`
const Title = styled.h3`
  font-size: 20px;
  font-family: QuicksandBold;
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`
const Date = styled.h3`
  color: rgb(131 131 131);
  font-size: 16px;
  font-family: Quicksand;
`

export default Card