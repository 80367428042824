import React from 'react'
import styled from 'styled-components'
import { Carousel } from 'react-bootstrap'
import { useStaticQuery, graphql } from 'gatsby'

import '../../../styles/carousel.css'
import Card from './Card'
import PrevIcon from '../../../assets/images/carousel-prev-icon.svg'
import NextIcon from '../../../assets/images/carousel-next-icon.svg'

const BootstrapCarousel = () => {

const data = useStaticQuery(graphql`
  query BootstrapCarouselQuery {
    allContentfulComponentDontJustTakeOurWordForIt(sort: { fields: [createdAt], order: ASC }) {
      nodes {
        content
        date
        type
        icon {
          gatsbyImageData(placeholder:NONE)
          title
        }
      }
    }
  }
`)

const cardsContent = data.allContentfulComponentDontJustTakeOurWordForIt.nodes

// there are 5 elements, every time show three of them from 0 index of the array, first set up a 'arr.length - 2' length empty array, then push three elements which are continuous with each other into the outer loop array. 
const getThreeContent = (arr) => {
  let newArr = new Array(arr.length - 2)
  for (let i = 0; i < arr.length - 2; i++) {
    newArr[i] = []
    newArr[i].push(arr[i], arr[i+1], arr[i+2]);
  }
  return newArr
}

  const newArr = getThreeContent(cardsContent)

  return (
    <CarouselWrapper>
      <ForBiggerScreen>
        <Carousel variant="dark">
            {newArr.map((items, index) => 
              <Carousel.Item key={index}>
                <CardGroup>
                  {items.map((item, index) => 
                    <Card
                      className="d-block w-100"
                      content={item.content}
                      title={item.type}
                      date={item.date}
                      icon={item.icon?.gatsbyImageData}
                      alt={item.icon.title}
                      key={index}
                    />
                  )}
                </CardGroup>
              </Carousel.Item>
            )}
        </Carousel>	
      </ForBiggerScreen>

      <ForSmallerScreen>
        <Carousel variant="dark">

          {cardsContent.map((item, index) => 
            <Carousel.Item key={index}>
              <CardGroup>
                <Card
                  className="d-block w-100"
                  content={item.content}
                  title={item.type}
                  date={item.date}
                  icon={item.icon?.gatsbyImageData}
                  alt={item.icon.title}
                  key={index}
                />
              </CardGroup>
            </Carousel.Item>
          )}
        </Carousel>	
      </ForSmallerScreen>
    </CarouselWrapper>
  )
}

const CarouselWrapper = styled.div`
  .carousel-control-prev {
    opacity: 1;
    justify-content: flex-start;
  }
  .carousel-control-next {
    opacity: 1;
    justify-content: flex-end;
  }
  .carousel-control-prev-icon{
    filter: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #d4dbe0;
    background-image : url(${PrevIcon});
  }
  .carousel-control-next-icon{
    filter: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #d4dbe0;
    background-image : url(${NextIcon});
  }
  .carousel-indicators {
    button {
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background-color: #d4dbe0 !important;
    }
    button.active {
      background-color: var(--base-navy) !important;
    }
  }
  @media only screen and (max-width: 991px) {
    // this is to make carousel-indicators on the bottom stay on the same position
    // if longer review are added in the future, change the min-height to be bigger
    .carousel {
      min-height: 520px !important;
    }
  }
  @media screen and (max-width: 767px) {
    .carousel {
      min-height: 450px !important;
    }
    .carousel-control-prev-icon{
      width: 40px;
      height: 40px;
    }
    .carousel-control-next-icon{
      width: 40px;
      height: 40px;
    }
  }
  @media screen and (max-width: 479px) {
    .carousel {
      min-height: 400px !important;
    }
    .carousel-control-prev {
      align-items: flex-end;
      margin-bottom: 16px;
    }
    .carousel-control-next {
      align-items: flex-end;
      margin-bottom: 16px;
    }
    .carousel-indicators {
      max-width: 100px;
      margin-right: auto;
      margin-left: auto;
    }
  }
  @media screen and (max-width: 418px) {
    .carousel {
      min-height: 450px !important;
    }
  }
`
const CardGroup = styled.div`
	display: flex;
	justify-content: center;
	align-items: flex-start;
	margin: 0 auto;
  > div {
    background-color: var(--white);
  }
`
const ForBiggerScreen = styled.div`
  @media screen and (max-width: 991px) {
    display: none
  };
  .gatsby-image-wrapper {
    max-width: 48px;
    overflow: hidden;
  }
  img {
    width: 100%;
    height: 100%;
  }
`
const ForSmallerScreen = styled.div`
  @media screen and (min-width: 991px) {
    display: none
  };

`
export default BootstrapCarousel