import React from 'react'
import  {Helmet, HelmetProvider } from 'react-helmet-async';

const AreatenSnippet = ({ slug, children, isProgPages = true }) => {
	// get the current date in YYYYMMDD format
	const today = new Date()
	const date = today.toLocaleDateString()
	const formateDate = date.slice(6,10) + date.slice(3,5) + date.slice(0,2)

	return (
    <HelmetProvider>
			<Helmet>
				<script src={`//proxyen.com/societyone.com.au/hera/js/article_${slug}.js?${formateDate}`} async></script>
				{isProgPages && <script src={`//proxyen.com/societyone.com.au/kronos/js/article_${slug}.js?${formateDate}`} async></script>}
			</Helmet>
			{ children }
    </HelmetProvider>
	)
}

export default AreatenSnippet