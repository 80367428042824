import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import BootstrapCarousel from './BootstrapCarousel'

const DontJustTake = ({background}) => {

	return (
		<ContentContainer background={background}>
			<div className="wrapper">
				<div className="main">
					<Header>Don't just take our word for it...</Header>
					<StarRatingImg>
						<StaticImage src='../../../assets/images/reviews.svg' alt="starRating" placeholder='none'/>
					</StarRatingImg>

					<BootstrapCarousel />
			
				</div>
			</div>
		</ContentContainer>
	)
}

const ContentContainer = styled.div`
	.wrapper {
		background: ${props => props.background || 'white'};
		.main {
			margin: 0 auto; 
			padding: 120px 0; 
			width: 97vw;
			@media screen and (max-width: 991px) {
				width: 100%;
				padding: 120px 24px;
			}
		}
	}
`


const StarRatingImg = styled.div`
	width: 680px;
	margin: 0 auto;
	@media screen and (max-width: 991px) {
		width: 80%;
	};

`
const Header = styled.h2`
	text-align: center; 
	font-size: 40px; 
	margin: 0 0 40px 0;
	@media screen and (max-width: 991px) {
		font-size: 32px; 
	};

`

export default DontJustTake